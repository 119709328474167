<script setup>
import { computed, onBeforeMount } from 'vue'
import { environment } from '@/config/environment'
import LanguageToolbar from '@access/components/LanguageToolbar'
import LoginForm from '@access/components/LoginForm'
import MicrosoftSignInButton from '@/app/modules/login/components/MicrosoftSignInButton.vue'

const canUseExternalProvider = computed(() => environment.checkFeatureFlagState('canUseExternalProvider'))

onBeforeMount(() => {
  localStorage.removeItem('token')
  localStorage.removeItem('workspace_id')
})
</script>

<template>
  <v-card class="mx-auto pb-5" max-width="35rem" width="100%" rounded="xl" elevation="5">
    <LanguageToolbar />

    <v-card class="mx-auto" max-width="30rem" width="100%" elevation="0">
      <v-card-title>
        <h1 class="login-title mb-0">{{ $t('access.login') }}</h1>
      </v-card-title>

      <v-card-text>
        <v-row align="center" justify="center">
          <v-col class="col-12">
            <LoginForm />
          </v-col>

          <v-col v-if="canUseExternalProvider" class="col-12 login-method__separator">
            <p>
              <span>{{ $t('general.or') }}</span>
            </p>
          </v-col>

          <v-col v-if="canUseExternalProvider" class="col-12">
            <MicrosoftSignInButton />
          </v-col>

          <v-col class="col-12 pt-2 pb-0">
            <router-link class="registration-link" :to="{ name: 'passwordForgotten' }">
              {{ $t('login.did_you_forget_password') }}
            </router-link>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<style lang="scss" scoped>
.login-title {
  color: #424242;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
}

.login-method__separator {
  p {
    color: #424242;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid #424242;
    line-height: 0.1em;
    letter-spacing: 0;
    margin: 10px 0 10px;
  }

  p span {
    background: #fff;
    padding: 0 10px;
  }
}

.registration-link {
  color: #424242;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17.5px;
}
</style>
